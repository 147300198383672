<template>
  <SidebarBase>
    <template #main>
      <ButtonSidebar
        v-if="!checks.isDraft"
        :active="view == 'dashboard'"
        label="Dashboard"
        @click.native="$emit('go-to-view', 'dashboard')"
      >
        <template #svg>
          <fw-icon-dashboard class="w-6 h-6" />
        </template>
      </ButtonSidebar>
      <ButtonSidebar :active="view == 'metadata'" label="Candidatura" @click.native="$emit('go-to-view', 'metadata')">
        <template #svg>
          <fw-icon-exam class="w-6 h-6" />
        </template>
      </ButtonSidebar>
      <VerticalSteps
        v-if="view == 'metadata'"
        :steps="steps"
        :current-step="section"
        @clicked="$emit('go-to-section', $event)"
      />
      <ButtonSidebar
        v-if="checks.isAccepted"
        :active="view == 'receipts'"
        label="Recibos"
        @click.native="$emit('go-to-view', 'receipts')"
      >
        <template #svg>
          <fw-icon-file-pdf class="w-6 h-6" />
        </template>
      </ButtonSidebar>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    VerticalSteps,
    ButtonSidebar,
    SidebarBase,
  },

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    checks: {
      type: Object,
      default: () => {},
    },
    view: {
      type: String,
      default: '',
    },
    section: {
      type: String,
      default: '',
    },
  },

  data() {
    return {}
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    callKey() {
      return this.$route.params.key
    },

    language() {
      return this.$store.state.language
    },
  },
}
</script>

<style lang="postcss">
a span:last-child {
  @apply text-black;
}
</style>

<i18n>
{
  "pt": {
    "home": "Início",
    "applications": "Candidaturas",
    "call": "Procedimento",
    "sections": "Seções",
    "status": {
      "draft": "Rascunho",
      "submitted": "Submetida",
      "canceled": "Cancelada"
    }
  },
  "en": {
    "home": "Home",
    "applications": "Applications",
    "call": "call",
    "sections": "Sections",
    "status": {
      "draft": "Rascunho",
      "submitted": "Submetida",
      "canceled": "Cancelada"
    }
  }
}
</i18n>
