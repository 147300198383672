<template>
  <fw-panel>
    <div>
      <fw-label>{{ $t('files') }}</fw-label>
      <div>
        <div v-if="tmpFiles.length > 0" class="files">
          <RecordFileEntry
            v-for="(file, f) in tmpFiles"
            :key="file.key"
            :allow-classified="false"
            :file="file"
            can-edit
            can-remove
            @save="saveFile(f, $event)"
            @remove="removeFile(f)"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader inline :files.sync="updateFilesToUpload" @upload="uploaded" />
      </div>
    </div>
    <div class="mt-2">
      <fw-label>{{ $t('description.label') }}</fw-label>
      <fw-editor v-model="message" :limit="2000" />
    </div>
    <div class="flex items-center justify-end gap-5 mt-5">
      <fw-button type="link-muted" :disabled="uploading" @click.native="close">
        {{ $t('cancel') }}
      </fw-button>
      <fw-button
        :type="canSave ? 'primary' : 'disabled'"
        class="px-5"
        :disabled="uploading || !canSave"
        :loading="uploading"
        @click.native="saveUploads"
      >
        {{ saveLabel || $t('save') }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    Uploader,
    RecordFileEntry,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    saveLabel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      uploading: false,
      tmpFiles: [],
      message: null,
      updateFilesToUpload: [],
    }
  },

  computed: {
    canSave() {
      return this.tmpFiles.length > 0
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    saveUploads() {
      this.$emit('save', {
        files: this.tmpFiles,
        message: this.message,
      })
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    removeFile(f) {
      console.log('remove file', f)
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: this.$t('deleteFileConfirm'),
        confirmText: this.$t('delete'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          this.tmpFiles.splice(f, 1)
        },
      })
    },

    saveFile(f, updatedFile) {
      console.log('save filename', f, updatedFile)
      this.$set(this.tmpFiles, f, { filename: updatedFile.title, key: updatedFile.key })
    },

    uploaded(files) {
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            this.tmpFiles.push({
              key: file.response.data.file.key,
              filename: file.response.data.file.filename,
            })
          }
        }
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "close": "Fechar",
    "save": "Guardar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "send": "Enviar",
    "description": {
      "label": "Mensagem (opcional)",
      "placeholder": "Escreva aqui a sua mensagem..."
    },
    "editMessage": "Editar mensagem",
    "files": "Ficheiros",
    "newMessage": "Nova mensagem",
    "publicMessage": "Mensagem não interna",
    "privateMessages": "Mensagem interna",
    "messageIsPublicConfirm": "A mensagem que está prestes a enviar não é interna. Tem a certeza que deseja continuar?",
    "selectThisOption": "Selecione esta opção para que esta mensagem seja apenas vísiveis a gestores e operadores.",
    "noMessages": "Sem mensagems",
    "addMessage": "Adicionar nova mensagem",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "uploadFiles": "Carregar ficheiros",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "errors": {
      "requiredMessage": "Insira um mensagem",
      "invalidMessage": "mensagem inválido"
    }
  },
  "en": {
    "save": "Save",
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "send": "Send",
    "description": {
      "label": "Message (optional)",
      "placeholder": "Write your message"
    },
    "files": "Ficheiros",
    "editMessage": "Edit message",
    "newMessage": "New message",
    "publicMessage": "Non-internal message",
    "privateMessages": "Private message",
    "messageIsPublicConfirm": "You are about to send a non-internal message. Are you sure?",
    "selectThisOption": "Select this option for messages to be visible only by those responsible.",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "uploadFiles": "Upload files",
    "errors": {
      "requiredMessage": "Enter a message",
      "invalidMessage": "Invalid message"
    }
  }
}
</i18n>
