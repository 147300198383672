<template>
  <div>
    <fw-panel title="Recibos" featured>
      <template v-if="canAddReceipts && !loading && receipts.length" #toolbar>
        <fw-button type="link" :disabled="loading" :loading="loading" @click.native="showAddReceiptsModal = true"
          >Submeter</fw-button
        >
      </template>
      <template #default>
        <LoadingPlaceholder v-if="loading" />
        <div v-else-if="receipts.length">
          <fw-panel
            title="Submetidos"
            :counter="receipts ? receipts.length : 0"
            :counter-total="totalResults"
            boxed
            class="my-5"
            custom-class="bg-white p-2"
          >
            <template v-for="item in receipts">
              <div
                :key="item.key"
                :class="{ 'opacity-50': item.state === 'rejected' }"
                class="p-1 border-b border-gray-100 flex gap-5 items-center hover:bg-gray-100 hover:opacity-100 rounded-lg"
              >
                <div class="flex-1">
                  <RecordFileEntry :file="item.files[0]" @download="downloadFile(item.files[0])">
                    <template #footer>
                      <div class="text-gray-500 text-xs flex gap-3">
                        <div>Submetido em {{ item.created_date | formatDateTime }}</div>
                        <div v-if="item.state === 'approved'" class="text-xs">
                          Aceite em {{ item.created_date | formatDateTime }}
                        </div>
                        <div v-else-if="item.state === 'rejected' && item.state_date" class="text-xs">
                          Rejeitado em {{ item.state_date | formatDateTime }}
                        </div>
                      </div>
                      <div v-if="item.state === 'rejected'" class="mt-2">
                        <div class="flex gap-1 text-sm px-3 pb-3">
                          <div>Justificação:</div>
                          <div class="font-medium" v-html="item.state_justification"></div>
                        </div>
                      </div>
                    </template>
                  </RecordFileEntry>
                </div>
                <div class="w-28">
                  <fw-tag :type="receiptStates[item.state].tagType" expanded>{{
                    receiptStates[item.state].label[language]
                  }}</fw-tag>
                </div>
              </div>
            </template>
          </fw-panel>
          <BlockPagination
            v-if="totalPages > 1 && !loading"
            :per-page="limit"
            :total="totalResults"
            :total-pages="totalPages"
            :current.sync="page"
            @page-changed="pageChanged"
          />
        </div>
        <div v-else class="text-center px-5 py-10 bg-gray-100 rounded-lg h-full text-gray-500 text-sm">
          <div class="max-w-sm mx-auto font-semibold">
            Ainda não submeteu recibos de pagamento no âmbito desta candidatura.
          </div>
          <div v-if="canAddReceipts" class="mt-5">
            <fw-button
              type="border-primary"
              :disabled="loading"
              :loading="loading"
              @click.native="showAddReceiptsModal = true"
              >Submeter recibos</fw-button
            >
          </div>
        </div>
      </template>
    </fw-panel>
    <fw-modal
      v-if="showAddReceiptsModal"
      title="Submeter recibos"
      :description="$t('uploadFilesDescription')"
      :active.sync="showAddReceiptsModal"
      boxed="sm"
      size="min"
      width="42rem"
      @close="closeAddReceiptsModal()"
    >
      <ModalUploadFiles save-label="Submeter" @close="closeAddReceiptsModal" @save="saveUploads" />
    </fw-modal>
  </div>
</template>

<script>
import ModalUploadFiles from '@/fw-modules/fw-core-vue/ui/components/modals/ModalUploadFiles'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import RecordFileEntry from '@/fw-modules/fw-core-vue/storage/components/records/RecordFileEntry'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import { APPLICATION_RECEIPTS_STATES } from '@/utils/index.js'

export default {
  components: {
    BlockPagination,
    ModalUploadFiles,
    LoadingPlaceholder,
    RecordFileEntry,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },
    application: {
      type: Object,
      default: () => {
        return {}
      },
    },
    call: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      loading: false,
      showAddReceiptsModal: false,
      receipts: [],
      results: {},
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 200,
      receiptStates: APPLICATION_RECEIPTS_STATES,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },

    // Checks / validations
    canAddReceipts() {
      return this.checks.isAccepted && !this.checks.isClosed
    },
  },

  watch: {
    results() {
      this.totalResults = this.results.pagination?.total_items
      this.totalPages = this.results.pagination?.total_pages
      this.page = this.results.pagination?.current_page
      this.receipts = this.results.receipts
    },
  },

  mounted() {
    this.getReceipts()
  },

  methods: {
    async getReceipts() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getApplicationReceipts(this.call.key, this.application.key)
          // We should be dealing with pagination here...
          // lets hope that noone will upload more than 200 receipts
          this.results = response
          this.receipts = response.receipts
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 250)
        }
      )
    },
    async saveUploads(payload) {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const uploaded = await this.api.uploadReceipts(this.call.key, this.application.key, payload)
          if (uploaded.files) {
            this.$buefy.snackbar.open({
              message: 'Recibos submetidos com sucesso.',
              type: 'is-success',
              position: 'is-bottom-right',
              duration: 5000,
            })
          }
          this.closeAddReceiptsModal()
          this.getReceipts()
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 250)
        }
      )
    },
    closeAddReceiptsModal() {
      this.showAddReceiptsModal = false
    },

    // Files
    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "uploadFilesDescription": "Para associar o(s) seu(s) recibo(s) ao seu processo, adicione-os à secção de ficheiros e clique em submeter. Após clicar em submeter, a interação será gravada e não poderá ser editada em momento posterior, por esse motivo, assegure-se que o conteúdo que envia é o correto."
  },
  "en": {
    "uploadFilesDescription": "Upload your payment receipts."
  }
}
</i18n>
