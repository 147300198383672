<template>
  <div>
    <div class="flex gap-10">
      <div class="flex-1">
        <fw-heading size="h2" muted>A sua candidatura</fw-heading>
        <div class="flex gap-1">
          <div>
            <fw-heading size="h1">{{ call.title[language] }}</fw-heading>
          </div>
          <div class="flex-shrink-0">
            <fw-tag type="light-border-box" size="xs" class="m-2"> {{ call.prefix }}{{ call.code }} </fw-tag>
          </div>
        </div>
        <div class="flex gap-10 mt-5 font-medium text-gray-500">
          <div>
            <fw-label marginless>Chave</fw-label>
            <div>{{ application.key }}</div>
          </div>
          <div>
            <fw-label marginless>Data de criação</fw-label>
            <div>{{ application.created_date | formatDateTime }}</div>
          </div>
          <div v-if="application.submitted_date">
            <fw-label marginless>Data de submissão</fw-label>
            <div class="flex items-center gap-1">
              <span>{{ application.submitted_date | formatDateTime }}</span>
              <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
            </div>
          </div>
        </div>
      </div>
      <div class="w-64">
        <div>
          <fw-label>Estado da candidatura</fw-label>
          <div>
            <fw-tag expanded size="xl" :type="applicationStates[application.state].color">{{
              applicationStates[application.state].label[language]
            }}</fw-tag>
          </div>
          <div v-if="isSubStateActive" class="py-1.5 font-medium">
            <div v-if="application.state === 'in_analysis_waiting'" class="text-yellow-500">
              <div>
                Aguardamos uma resposta sua
                <span v-if="application.answer_deadline"
                  >até {{ application.answer_deadline.date | formatDateTime }}</span
                >.
              </div>
              <div class="text-xs text-gray-500">Utilize o painel de mensagens para responder.</div>
            </div>
            <div
              v-if="
                application.state === 'complaint' &&
                  application.answer_deadline &&
                  application.answer_deadline.remaining_time > 0
              "
              class="text-sm text-gray-500"
            >
              <div>Até {{ application.answer_deadline.date | formatDateTime }}.</div>
              <div class="text-xs text-gray-500">
                Querendo, utilize o painel de mensagens para apresentar a sua pronúncia.
              </div>
            </div>
            <div v-if="application.state === 'complaint_analysis'" class="text-sm text-gray-500">
              <div>A sua pronúncia foi submetida e será em breve analisada.</div>
            </div>
          </div>
        </div>
        <div v-if="application.decision" class="mt-3">
          <fw-label marginless>Decisão</fw-label>
          <div class="text-gray-700">
            <div v-if="application.decision === 'accepted'" class="text-xl font-semibold">
              Candidatura aceite
            </div>
            <div v-else class="text-xl font-semibold">Candidatura rejeitada</div>
            <div class="text-sm font-medium">{{ application.decision_date | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <fw-panel v-if="!checks.isDraft && !checks.isCanceled" :title="$t('statusMainBoardTitle')" class="my-10">
      <div class="flex flex-col gap-4">
        <div class="flex gap-3 text-gray-500">
          <template v-for="(status, key) in applicationStatesForPanel">
            <div
              v-if="status.show"
              :key="key"
              :class="{
                'w-2/4 border-primary min-h-28': status.isRunning,
                'w-1/4 border-white min-h-28': !status.isRunning,
                'text-primary': status.isActive,
              }"
              class="relative rounded-xl bg-white border p-4 flex flex-col text-center font-semibold items-center gap-1 group"
            >
              <div class="w-full flex items-center justify-center">
                <div v-if="status.isDone">
                  <fw-icon-check-solid class="w-6 h-6 flex-shrink-0" />
                </div>
                <div v-else-if="status.isRunning">
                  <fw-icon-run class="w-6 h-6 flex-shrink-0 text-primary animate-pulse" />
                </div>
                <div v-else>
                  <fw-icon-rest class="w-6 h-6 flex-shrink-0 text-gray-300" />
                </div>
              </div>
              <div
                class="flex-1"
                :class="{
                  'font-bold': status.isActive || status.isDone,
                }"
              >
                {{ status.label }}
              </div>
              <div class="flex justify-center items-center font-normal text-xs">
                <div v-if="status.isRunning" class="text-primary">
                  {{ $t('phaseOngoing') }}
                </div>
                <div v-else-if="status.isDone && key !== 'closed'">
                  {{ $t('phaseFinished') }}
                </div>
                <div v-else-if="!status.isDone && !status.isActive && status.canChange">
                  {{ $t('phasePending') }}
                </div>
                <div v-else-if="key === 'closed' && status.isDone">
                  {{ $t('phaseClosed') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </fw-panel>
    <fw-panel-info v-if="isAcceptedAndStillRunning" type="orange" boxed centered class="my-5">
      Para adicionar novos recibos de pagamento, por favor, utilize a secção
      <a class="font-semibold" @click.prevent="$emit('go-to-view', 'receipts')">Recibos</a> disponível no menu
      principal.
    </fw-panel-info>
    <div v-if="!checks.isDraft && !checks.isCanceled" class="flex gap-5">
      <div class="flex-1">
        <fw-panel title="Mensagens" :counter="messages.length" class="h-full">
          <PanelMessages
            :add-message-button-label="
              application.state === 'in_analysis_waiting'
                ? $t('reply')
                : application.state === 'complaint'
                ? $t('addComplaint')
                : null
            "
            :add-message-button-type="
              ['in_analysis_waiting', 'complaint'].includes(application.state) ? 'orange' : 'primary'
            "
            add-message-modal-instructions="Após enviar a mensagem, a mesma não poderá ser editada ou eliminada. Doravante, a sua interação através do painel de mensagens só poderá ter lugar na eventualidade de lhe serem solicitados esclarecimentos."
            :users="messagesUsers"
            :messages="messages"
            :pagination="paginationMessages"
            :loading="loadingMessages"
            :show-public-private-toggle="false"
            :can-add-message="checks.canSendMessages"
            :default-add-message-is-private="false"
            :ignore-confirm-public-message="true"
            class="h-full"
            @page-changed="messagesPageChanged"
            @add-message="addMessage"
            @update-message="updateMessage"
            @delete-message="deleteMessage"
          />
        </fw-panel>
      </div>
      <div v-if="false" class="w-1/3">
        <fw-panel title="Recibos" class="h-full" custom-class="bg-gray-200 bg-opacity-80" boxed="sm">
          <div class="text-xs text-gray-500 h-full items-center justify-start flex text-center p-5 flex-col gap-2">
            <div class="bg-gray-300 flex items-center justify-center rounded-full p-2 text-gray-500">
              <fw-icon-file class="w-5 h-5" />
            </div>
            <div>
              Caso a sua candidatura seja aceite, poderá submeter os próximos recibos de pagamento do alojamento nesta
              área.
            </div>
          </div>
        </fw-panel>
      </div>
    </div>
  </div>
</template>

<script>
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  components: {
    PanelMessages,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    application: {
      type: Object,
      default: () => {
        return {}
      },
    },

    call: {
      type: Object,
      default: () => {
        return {}
      },
    },

    savingData: {
      type: Boolean,
    },
  },

  data() {
    return {
      loadingMessages: false,
      messages: [],
      messagesUsers: {},
      paginationMessages: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      applicationStates: APPLICATION_STATES['scholarship'],
      subStates: ['in_analysis_waiting', 'complaint', 'complaint_analysis'],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    isSubStateActive() {
      return this.application && this.subStates.includes(this.application.state)
    },
    isAcceptedAndStillRunning() {
      return (
        !['closed', 'draft'].includes(this.application.state) &&
        this.application.decision &&
        this.application.decision === 'accepted'
      )
    },
    applicationStatesForPanel() {
      return {
        submitted: {
          label: this.applicationStates.submitted.label[this.language],
          canChange: true,
          isActive: this.application.state === 'submitted',
          isRunning: false,
          isDone: this.application.state !== 'draft',
          show: true,
        },
        in_analysis: {
          label: this.applicationStates.in_analysis.label[this.language],
          canChange: true,
          isRunning: ['in_analysis', 'in_analysis_waiting'].includes(this.application.state),
          isActive: ['in_analysis', 'in_analysis_waiting'].includes(this.application.state),
          isDone: ['in_progress', 'complaint', 'complaint_analysis', 'closed'].includes(this.application.state),
          show: true,
        },
        in_progress: {
          label: this.applicationStates.in_progress.label[this.language],
          canChange: true,
          isRunning: ['in_progress'].includes(this.application.state),
          isActive: ['in_progress'].includes(this.application.state),
          isDone: ['closed'].includes(this.application.state),
          show: this.application.decision === 'accepted' && this.application.state !== 'closed',
        },
        complaint: {
          label: this.applicationStates.complaint.label[this.language],
          canChange: true,
          isRunning: ['in_progress', 'complaint', 'complaint_analysis'].includes(this.application.state),
          isActive: ['in_progress', 'complaint', 'complaint_analysis'].includes(this.application.state),
          isDone: ['closed'].includes(this.application.state),
          show: this.application.decision === 'rejected',
        },
        closed: {
          label: this.applicationStates.closed.label[this.language],
          canChange: false,
          isRunning: false,
          isActive: false,
          isDone: this.application.state === 'closed',
          show: true,
        },
      }
    },
    isCanceled() {
      return this.application.state === 'canceled'
    },
  },

  mounted() {
    this.getMessages()
  },

  methods: {
    async getMessages() {
      this.loadingMessages = true

      try {
        console.log('getMessages params :>> ', this.paginationMessages)
        const response = await this.api.getApplicationMessages(this.call.key, this.application.key, {
          page: this.paginationMessages.page,
          limit: this.paginationMessages.limit,
        })
        console.log('getMessages :>> ', response)
        this.messages = response.messages
        this.messagesUsers = { ...this.messagesUsers, ...response.users }
        this.paginationMessages = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getMessages :>> ', e)
      }

      this.loadingMessages = false
    },
    async addMessage(messageData) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.addApplicationMessages(this.call.key, this.application.key, messageData)
        console.log('response :>> ', response)
        console.log('addedMessage :>> ', response)
        this.messages.unshift(response.message)
        this.$emit('added-message', response)
      } catch (error) {
        console.log('error :>> ', error)
        this.savingMessageError = true
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSavingMessage'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },
    async updateMessage(messageData) {
      try {
        const response = await this.api.updateApplicationMessage(
          this.call.key,
          this.application.key,
          messageData.key,
          messageData
        )
        console.log('updatedMessage :>> ', response)
        let index = this.messages.findIndex(el => el.key == response.key)
        if (index > -1) this.$set(this.messages, index, response)
      } catch (error) {
        console.log('error :>> ', error)
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSavingMessage'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },
    deleteMessage(messageKey) {
      this.messages = this.messages.filter(el => el.key != messageKey)
    },
    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.paginationMessages.page = page
      this.getMessages()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "key": "Chave",
    "createdAt": "Criado em",
    "submittedAt": "Publicado em",
    "notDefined": "Não definido",
    "callType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho"
    },
    "decision": "Decisão",
    "decisionType": {
      "accepted": "Aceite",
      "rejected": "Rejeitada"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Processo terminado",
    "reply": "Responder",
    "addComplaint": "Apresentar pronúncia"
  },
  "en": {
    "statusMainBoardTitle": "Estado",
    "key": "Chave",
    "createdAt": "Criado em",
    "submittedAt": "Publicado em",
    "notDefined": "Não definido",
    "decision": "Decisão",
    "decisionType": {
      "accepted": "Aceite",
      "rejected": "Rejeitada"
    },
    "callType": {
      "scholarship": "Apoio",
      "occupational_medicine": "Occupational Medicine"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "phaseClosed": "Processo terminado",
    "reply": "Responder",
    "addComplaint": "Apresentar pronúncia"
  }
}
</i18n>
