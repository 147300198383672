<template>
  <div class="relative">
    <fw-panel :title="$t('application')" featured sticky :loading="savingData" after-loading-checked class="py-5 px-1">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div
            v-if="$v.application.data.$error"
            class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
          >
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('hasErrors') }}</span>
          </div>

          <div v-if="checks.canEdit" class="flex gap-5">
            <fw-button
              :type="checks.canSubmit ? 'light-primary' : 'primary'"
              :disabled="savingData"
              :loading="savingData"
              @click.native="saveApplication"
              >{{ $t('save') }}</fw-button
            >
          </div>
        </div>
      </template>
    </fw-panel>

    <fw-panel
      id="form_section_personal_data"
      :title="$t('personalData')"
      class="my-5 px-2"
      boxed="lg"
      custom-class="bg-white"
    >
      <div>
        <fw-label>{{ $t('fullName') }}</fw-label>
        <TextInput
          v-if="checks.canEdit"
          v-model="application.data.name"
          :disabled="!checks.canEdit"
          :minlength="3"
          :maxlength="250"
          autocomplete="new-password"
          :class="{
            error: $v.application.data.name.$error,
          }"
        ></TextInput>

        <div v-else>
          <div v-if="application.data.name">
            {{ application.data.name }}
          </div>
          <fw-panel-info v-else clean>{{ application.data.name || $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.application.data.name.$error" error>
          <span v-if="!$v.application.data.name.required">{{ $t('errors.required.name') }}</span>
          <span v-else>{{ $t('errors.invalid.name') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4 flex gap-4">
        <div>
          <fw-label>{{ $t('phoneCountry') }}</fw-label>
          <PhoneCountriesSelect
            v-if="checks.canEdit"
            :disabled="!checks.canEdit"
            :placeholder="$t('phoneCountry')"
            :input="application.data.phone_country_code"
            @update="phoneCountryChanged($event)"
          />
          <div v-else>
            <div v-if="application.data.phone_country_code && application.data.phone_country_code">
              {{ application.data.phone_country_code }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
        </div>
        <div>
          <fw-label>{{ $t('phone') }}</fw-label>
          <NumberInput
            v-if="checks.canEdit"
            v-model="application.data.phone_number"
            :placeholder="$t('phone')"
            :maxlength="20"
            :disabled="!checks.canEdit"
            :class="{
              error: $v.application.data.phone_number.$error,
            }"
          ></NumberInput>
          <div v-else>
            <div v-if="application.data.phone_number">
              {{ application.data.phone_number }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.phone_number.$error" error>
            <span v-if="!$v.application.data.phone_number.required">{{ $t('errors.required.phoneNumber') }}</span>
            <span v-else>{{ $t('errors.invalid.phoneNumber') }}</span>
          </fw-tip>
        </div>
      </div>

      <fw-heading size="h4" class="mt-10">{{ $t('id.label') }}</fw-heading>
      <div class="flex mt-2 gap-4">
        <div>
          <fw-label>{{ $t('id.type.label') }}</fw-label>
          <b-select
            v-if="checks.canEdit"
            v-model="application.data.identity_type"
            class="fw-select w-full"
            :disabled="!checks.canEdit"
            :placeholder="$t('id.type.placeholder')"
          >
            <option value="cc">{{ $t('id.type.cc') }}</option>
            <option value="passport">{{ $t('id.type.passport') }}</option>
            <option value="residence_permit">{{ $t('id.type.residence_permit') }}</option>
          </b-select>
          <div v-else>
            <div v-if="application.data.identity_type">
              {{ $t(`id.type.${application.data.identity_type}`) }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.identity_type.$error" error>
            {{ $t('errors.required.identityType') }}
          </fw-tip>
        </div>
        <div>
          <fw-label>{{ $t('id.number') }}</fw-label>
          <TextInput
            v-if="checks.canEdit"
            v-model="application.data.identity_value"
            :placeholder="application.data.identity_type == 'cc' ? 'DDDDDDDDCAAT' : 'XXXXXXXXX'"
            :disabled="!checks.canEdit"
            :class="{
              error: $v.application.data.identity_value.$error,
            }"
            :minlength="3"
            :maxlength="50"
          ></TextInput>
          <div v-else>
            <div v-if="application.data.identity_value">
              {{ application.data.identity_value }}
            </div>
            <fw-panel-info v-else clean>{{ application.data.identity_value || $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.identity_value.$error" error>
            <span v-if="!$v.application.data.identity_value.required">{{ $t('errors.required.identityCode') }}</span>
            <span v-else>{{ $t('errors.invalid.identityCode') }}</span>
          </fw-tip>
        </div>
        <div>
          <fw-label>{{ $t('id.validDate.label') }}</fw-label>
          <b-datepicker
            v-if="checks.canEdit"
            v-model="application.data.identity_expire_date"
            :disabled="!checks.canEdit"
            :min-date="minDate"
            :years-range="[-10, 50]"
            locale="pt-PT"
            :placeholder="$t('id.validDate.placeholder')"
          >
          </b-datepicker>
          <div v-else>
            <div v-if="application.data.identity_expire_date">
              {{ application.data.identity_expire_date | formatDate }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.identity_expire_date.$error" error>
            <span v-if="!$v.application.data.identity_expire_date.required">{{
              $t('errors.required.identityExpireDate')
            }}</span>
            <span v-else>{{ $t('errors.invalid.identityExpireDate') }}</span>
          </fw-tip>
        </div>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('vat') }}</fw-label>
        <div v-if="checks.canEdit">
          <NumberInput
            v-model="application.data.vat_value"
            :disabled="!checks.canEdit"
            :maxlength="9"
            :placeholder="$t('vat')"
            :class="{
              error: $v.application.data.vat_value.$error,
            }"
            class="inline-block"
          ></NumberInput>
        </div>
        <div v-else>
          <div v-if="application.data.vat_value">
            {{ application.data.vat_value }}
          </div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>
        <fw-tip v-if="$v.application.data.vat_value.$error" error>
          <span v-if="!$v.application.data.vat_value.required">{{ $t('errors.required.vat') }}</span>
          <span v-else>{{ $t('errors.invalid.vat') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>IBAN (português)</fw-label>
        <div v-if="checks.canEdit" class="flex gap-0.5 items-center bg-gray-100 rounded-lg">
          <div class="pl-5 text-gray-600">PT50</div>
          <div class="flex-1">
            <TextInput
              v-model="application.data.iban"
              placeholder="XXXXXXXXXXXXXXXXXXXXX"
              :disabled="!checks.canEdit"
              :class="{
                error: $v.application.data.iban.$error,
              }"
              :minlength="21"
              :maxlength="21"
            ></TextInput>
          </div>
        </div>
        <div v-else>
          <div v-if="application.data.iban">
            <span class="text-gray-600 mr-1">PT50</span>{{ application.data.iban }}
          </div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.application.data.iban.$error" error>
          <span v-if="!$v.application.data.iban.required">{{ $t('errors.required.iban') }}</span>
          <span v-else>{{ $t('errors.invalid.iban') }}</span>
        </fw-tip>

        <div class="my-2">
          <fw-label>{{ $t('isAccountHolder') }}</fw-label>
          <div>
            <OnoffOption
              v-if="checks.canEdit"
              :disabled="!checks.canEdit"
              :off-as-gray="false"
              :options="[$t('no'), $t('yes')]"
              :selected-option="
                [false, 0].includes(application.data.is_account_holder)
                  ? $t('no')
                  : [true, 1].includes(application.data.is_account_holder)
                  ? $t('yes')
                  : null
              "
              @input="applicantIsAccountHolderChanged"
            />

            <div v-if="!checks.canEdit">
              <div
                v-if="application.data.is_account_holder !== null"
                class="px-3 py-1 text-sm font-semibold  rounded-full inline mt-2"
                :class="{
                  'bg-gray-200': !application.data.is_account_holder,
                  'bg-primary bg-opacity-30': application.data.is_account_holder,
                }"
              >
                {{ application.data.is_account_holder ? $t('yes') : $t('no') }}
              </div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
          </div>
        </div>

        <div v-if="application.data.is_account_holder == false">
          <fw-label>{{ $t('accountHolder') }}</fw-label>
          <TextInput
            v-if="checks.canEdit"
            v-model="application.data.account_holder"
            :disabled="!checks.canEdit"
            :minlength="3"
            :maxlength="250"
            autocomplete="new-password"
            :class="{
              error: $v.application.data.account_holder.$error,
            }"
          ></TextInput>
          <div v-else>
            <div v-if="application.data.account_holder">
              {{ application.data.account_holder }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.account_holder.$error" error>
            <span v-if="!$v.application.data.account_holder.required">{{ $t('errors.required.accountHolder') }}</span>
            <span v-else>{{ $t('errors.invalid.accountHolder') }}</span>
          </fw-tip>
        </div>
      </div>

      <fw-heading size="h4" class="mt-10">Residência do agregado familiar</fw-heading>
      <div class="mt-4">
        <fw-label>{{ $t('address') }}</fw-label>
        <TextInput
          v-if="checks.canEdit"
          v-model="application.data.address"
          :disabled="!checks.canEdit"
          :minlength="3"
          :maxlength="250"
          :placeholder="$t('address')"
          :class="{
            error: $v.application.data.address.$error,
          }"
        ></TextInput>
        <div v-else>
          <div v-if="application.data.address">
            {{ application.data.address }}
          </div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>

        <fw-tip v-if="$v.application.data.address.$error" error>
          <span v-if="!$v.application.data.address.required">{{ $t('errors.required.address') }}</span>
          <span v-else>{{ $t('errors.invalid.address') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4 flex gap-4">
        <div>
          <fw-label>{{ $t('postalCode') }}</fw-label>
          <TextInput
            v-if="checks.canEdit"
            v-model="application.data.postal_code"
            placeholder="XXXX-XXX"
            :maxlength="8"
            :disabled="!checks.canEdit"
            :class="{
              error: $v.application.data.postal_code.$error,
            }"
          ></TextInput>
          <div v-else>
            <div v-if="application.data.postal_code">
              {{ application.data.postal_code }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.postal_code.$error" error>
            <span v-if="!$v.application.data.postal_code.required">{{ $t('errors.required.postalCode') }}</span>
            <span v-else>{{ $t('errors.invalid.postalCode') }}</span>
          </fw-tip>
        </div>
        <div>
          <fw-label>{{ $t('locality') }}</fw-label>
          <TextInput
            v-if="checks.canEdit"
            v-model="application.data.locality"
            :placeholder="$t('locality')"
            :maxlength="250"
            :disabled="!checks.canEdit"
            :class="{
              error: $v.application.data.locality.$error,
            }"
          ></TextInput>
          <div v-else>
            <div v-if="application.data.locality">
              {{ application.data.locality }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.locality.$error" error>
            <span v-if="!$v.application.data.locality.required">{{ $t('errors.required.locality') }}</span>
            <span v-else>{{ $t('errors.invalid.locality') }}</span>
          </fw-tip>
        </div>
      </div>
      <div class="mt-4">
        <fw-label>{{ $t('country') }}</fw-label>
        <CountriesSelect
          v-if="checks.canEdit"
          :placeholder="$t('country')"
          :disabled="!checks.canEdit"
          :input="application.data.country_code"
          @update="countryChanged($event)"
        />
        <div v-else>
          <div v-if="application.data.country_code && application.data.country_code">
            {{ application.data.country_code }}
          </div>
          <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
        </div>
        <fw-tip v-if="$v.application.data.country_code.$error" error>
          <span v-if="!$v.application.data.country_code.required">{{ $t('errors.required.country') }}</span>
          <span v-else>{{ $t('errors.invalid.country') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-heading size="h4" class="mt-10">{{ $t('schoolresidency') }}</fw-heading>
        <div class="mt-4">
          <fw-label>{{ $t('address') }}</fw-label>
          <TextInput
            v-if="checks.canEdit"
            v-model="application.data.address_during_classes.address"
            :disabled="!checks.canEdit"
            :minlength="3"
            :maxlength="250"
            :placeholder="$t('address')"
            :class="{
              error: $v.application.data.address_during_classes.address.$error,
            }"
          ></TextInput>
          <div v-else>
            <div v-if="application.data.address_during_classes.address">
              {{ application.data.address_during_classes.address }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>

          <fw-tip v-if="$v.application.data.address_during_classes.address.$error" error>
            <span v-if="!$v.application.data.address_during_classes.address.required">{{
              $t('errors.required.address')
            }}</span>
            <span v-else>{{ $t('errors.invalid.address') }}</span>
          </fw-tip>
        </div>

        <div class="mt-4 flex gap-4">
          <div>
            <fw-label>{{ $t('postalCode') }}</fw-label>
            <TextInput
              v-if="checks.canEdit"
              v-model="application.data.address_during_classes.postal_code"
              placeholder="XXXX-XXX"
              :maxlength="8"
              :disabled="!checks.canEdit"
              :class="{
                error: $v.application.data.address_during_classes.postal_code.$error,
              }"
            ></TextInput>
            <div v-else>
              <div v-if="application.data.address_during_classes.postal_code">
                {{ application.data.address_during_classes.postal_code }}
              </div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
            <fw-tip v-if="$v.application.data.address_during_classes.postal_code.$error" error>
              <span v-if="!$v.application.data.address_during_classes.postal_code.required">{{
                $t('errors.required.postalCode')
              }}</span>
              <span v-else>{{ $t('errors.invalid.postalCode') }}</span>
            </fw-tip>
          </div>
          <div>
            <fw-label>{{ $t('locality') }}</fw-label>
            <TextInput
              v-if="checks.canEdit"
              v-model="application.data.address_during_classes.locality"
              :placeholder="$t('locality')"
              :maxlength="250"
              :disabled="!checks.canEdit"
              :class="{
                error: $v.application.data.address_during_classes.locality.$error,
              }"
            ></TextInput>
            <div v-else>
              <div v-if="application.data.address_during_classes.locality">
                {{ application.data.address_during_classes.locality }}
              </div>
              <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
            </div>
            <fw-tip v-if="$v.application.data.address_during_classes.locality.$error" error>
              <span v-if="!$v.application.data.address_during_classes.locality.required">{{
                $t('errors.required.locality')
              }}</span>
              <span v-else>{{ $t('errors.invalid.locality') }}</span>
            </fw-tip>
          </div>
        </div>
        <div class="mt-4">
          <fw-label>{{ $t('country') }}</fw-label>
          <CountriesSelect
            v-if="checks.canEdit"
            :placeholder="$t('country')"
            :disabled="!checks.canEdit"
            :input="application.data.address_during_classes.country_code"
            @update="countryChanged($event, 'address_during_classes')"
          />
          <div v-else>
            <div
              v-if="
                application.data.address_during_classes.country_code &&
                  application.data.address_during_classes.country_code
              "
            >
              {{ application.data.address_during_classes.country_code }}
            </div>
            <fw-panel-info v-else clean>{{ $t('notDefined') }}</fw-panel-info>
          </div>
          <fw-tip v-if="$v.application.data.address_during_classes.country_code.$error" error>
            <span v-if="!$v.application.data.address_during_classes.country_code.required">{{
              $t('errors.required.country_code')
            }}</span>
            <span v-else>{{ $t('errors.invalid.country_code') }}</span>
          </fw-tip>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      id="form_section_academic_info"
      :title="$t('academicInfo.title')"
      class="my-5 px-2"
      boxed="lg"
      custom-class="bg-white"
    >
      <div>
        <fw-label>{{ $t('academicInfo.studentNumber.label') }}</fw-label>
        <TextInput
          v-model="application.data.academic_info.student_number"
          :disabled="true"
          :maxlength="12"
          :placeholder="$t('academicInfo.studentNumber.placeholder')"
          class="w-36"
          :class="{
            error: $v.application.data.academic_info.student_number.$error,
          }"
        ></TextInput>
        <fw-tip v-if="$v.application.data.academic_info.student_number.$error" error>
          <span v-if="!$v.application.data.academic_info.student_number.required">{{
            $t('errors.required.studentNumber')
          }}</span>
          <span v-else>{{ $t('errors.invalid.studentNumber') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('academicInfo.course.label') }}</fw-label>
        <TextInput
          v-model="application.data.academic_info.course"
          :disabled="!checks.canEdit"
          :maxlength="250"
          :placeholder="$t('academicInfo.course.placeholder')"
          :class="{
            error: $v.application.data.academic_info.course.$error,
          }"
        ></TextInput>
        <fw-tip v-if="$v.application.data.academic_info.course.$error" error>
          <span v-if="!$v.application.data.academic_info.course.required">{{ $t('errors.required.course') }}</span>
          <span v-else>{{ $t('errors.invalid.course') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('academicInfo.studyCycle.label') }}</fw-label>
        <b-select
          v-model="application.data.academic_info.study_cycle"
          class="fw-select w-full"
          :class="{
            error: $v.application.data.academic_info.study_cycle.$error,
          }"
          :disabled="!checks.canEdit"
        >
          <option value="1st">1º Ciclo - Licenciatura</option>
          <option value="2nd">2º Ciclo - Mestrado</option>
          <option value="1st-2nd">1º e 2º Ciclo - Mestrado Integrado</option>
          <option value="3rd">3º Ciclo - Doutoramento</option>
        </b-select>
        <fw-tip v-if="$v.application.data.academic_info.study_cycle.$error" error>
          <span v-if="!$v.application.data.academic_info.study_cycle.required">{{
            $t('errors.required.studyCycle')
          }}</span>
          <span v-else>{{ $t('errors.invalid.studyCycle') }}</span>
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('academicInfo.institution.label') }}</fw-label>
        <b-select
          v-model="application.data.academic_info.institution"
          class="fw-select w-full"
          :class="{
            error: $v.application.data.academic_info.institution.$error,
          }"
          :disabled="!checks.canEdit"
        >
          <option value="fluc">Faculdade de Letras</option>
          <option value="fduc">Faculdade de Direito</option>
          <option value="fmuc">Faculdade de Medicina</option>
          <option value="fctuc">Faculdade de Ciências e Tecnologia</option>
          <option value="ffuc">Faculdade de Farmácia</option>
          <option value="feuc">Faculdade de Economia</option>
          <option value="fpce">Faculdade de Psicologia e de Ciências da Educação</option>
          <option value="fcdef">Faculdade de Ciências do Desporto e Educação Física</option>
          <option value="ca">Colégio das Artes</option>
          <option value="iii">Instituto de Investigação Interdisciplinar</option>
        </b-select>
        <fw-tip v-if="$v.application.data.academic_info.institution.$error" error>
          <span v-if="!$v.application.data.academic_info.institution.required">{{
            $t('errors.required.institution')
          }}</span>
          <span v-else>{{ $t('errors.invalid.institution') }}</span>
        </fw-tip>
      </div>
    </fw-panel>

    <fw-panel id="form_section_files" :title="$t('files.title')" class="my-5 px-2" boxed="lg" custom-class="bg-white">
      <div>
        <fw-label>{{ $t('files.householdFiscalAddress.title') }}</fw-label>
        <div
          v-if="application.files.household_fiscal_address && application.files.household_fiscal_address.length > 0"
          class="files mb-1.5"
        >
          <RecordFileEntry
            v-for="file in application.files.household_fiscal_address"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'household_fiscal_address')"
            @remove="removeFile(file, 'household_fiscal_address')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_household_fiscal_address"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_household_fiscal_address"
          :files.sync="householdFiscalAddressFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="household_fiscal_address"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'household_fiscal_address')"
        />
        <fw-tip v-if="$v.application.files.household_fiscal_address.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('files.familyAllowance.title') }}</fw-label>
        <div
          v-if="application.files.family_allowance && application.files.family_allowance.length > 0"
          class="files mb-1.5"
        >
          <RecordFileEntry
            v-for="file in application.files.family_allowance"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'family_allowance')"
            @remove="removeFile(file, 'family_allowance')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_family_allowance"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_family_allowance"
          :files.sync="familyAllowanceFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="family_allowance"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'family_allowance')"
        />
        <fw-tip v-if="$v.application.files.family_allowance.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('files.permanentResidence.title') }}</fw-label>
        <div
          v-if="application.files.permanent_residence && application.files.permanent_residence.length > 0"
          class="files mb-1.5"
        >
          <RecordFileEntry
            v-for="file in application.files.permanent_residence"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'permanent_residence')"
            @remove="removeFile(file, 'permanent_residence')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_permanent_residence"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_permanent_residence"
          :files.sync="permanentResidenceFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="permanent_residence"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'permanent_residence')"
        />
        <fw-tip v-if="$v.application.files.permanent_residence.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('files.nonExistenceOfDebts.title') }}</fw-label>
        <div
          v-if="application.files.non_existence_of_debts && application.files.non_existence_of_debts.length > 0"
          class="files mb-1.5"
        >
          <RecordFileEntry
            v-for="file in application.files.non_existence_of_debts"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'non_existence_of_debts')"
            @remove="removeFile(file, 'non_existence_of_debts')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_non_existence_of_debts"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_non_existence_of_debts"
          :files.sync="nonExistenceOfDebtsFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="non_existence_of_debts"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'non_existence_of_debts')"
        />
        <fw-tip v-if="$v.application.files.non_existence_of_debts.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('files.paymentReceipts.title') }}</fw-label>
        <div
          v-if="application.files.payment_receipts && application.files.payment_receipts.length > 0"
          class="files mb-1.5"
        >
          <RecordFileEntry
            v-for="file in application.files.payment_receipts"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'payment_receipts')"
            @remove="removeFile(file, 'payment_receipts')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_payment_receipts"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_payment_receipts"
          :files.sync="paymentReceiptsFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="payment_receipts"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'payment_receipts')"
        />
        <fw-tip v-if="$v.application.files.payment_receipts.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>

      <div class="mt-4">
        <fw-label>{{ $t('files.iban.title') }}</fw-label>
        <div v-if="application.files.iban && application.files.iban.length > 0" class="files mb-1.5">
          <RecordFileEntry
            v-for="file in application.files.iban"
            :key="file.key"
            :can-edit="checks.canEdit"
            :can-remove="checks.canEdit"
            :allow-classified="false"
            :file="file"
            @save="saveFile(file, 'iban')"
            @remove="removeFile(file, 'iban')"
            @download="downloadFile(file)"
          />
        </div>
        <Uploader
          v-if="checks.canEdit"
          :label="$t('uploadFiles')"
          :is-docked="true"
          layout="minimal"
          reference-id="uploader_iban"
          allowed="all"
          :clear-after="true"
          input-id="upload_input_iban"
          :files.sync="ibanFiles"
          :size="0"
          :new-file-context="{}"
          file-type="file"
          file-code="iban"
          class="cursor-pointer update-modal-uploader"
          uploader-class="w-full rounded"
          @upload="uploaded($event, 'iban')"
        />
        <fw-tip v-if="$v.application.files.iban.$error" error>
          {{ $t('errors.required.files') }}
        </fw-tip>
      </div>
    </fw-panel>

    <fw-panel
      id="form_section_declaration"
      :title="$t('declarationOfHonor')"
      class="my-5 px-2"
      boxed="sm"
      custom-class="bg-white"
    >
      <div class="mt-2">
        <b-switch v-model="application.data.accept_extraordinary_support_for_housing" :disabled="!checks.canEdit">
          {{ this.$t('accept_extraordinary_support_for_housing') }}
        </b-switch>
        <fw-tip v-if="$v.application.data.accept_extraordinary_support_for_housing.$error" error>
          {{ $t('errors.required.accept_extraordinary_support_for_housing') }}
        </fw-tip>
      </div>
      <div class="mt-2">
        <b-switch v-model="application.data.entered_required_docs" :disabled="!checks.canEdit">
          {{ this.$t('entered_required_docs') }}
        </b-switch>
        <fw-tip v-if="$v.application.data.entered_required_docs.$error" error>
          {{ $t('errors.required.entered_required_docs') }}
        </fw-tip>
      </div>
      <div class="mt-2">
        <b-switch v-model="application.data.accept_data_treatment" :disabled="!checks.canEdit">
          {{ this.$t('accept_data_treatment') }}
        </b-switch>
        <fw-tip v-if="$v.application.data.accept_data_treatment.$error" error>
          {{ $t('errors.required.accept_data_treatment') }}
        </fw-tip>
        <div class="text-sm text-gray-600 ml-14 my-3">
          <p class="font-semibold">
            Informação legal e consentimento informado sobre tratamento de dados pessoais na UC
          </p>
          <p>
            1) A recolha dos meus dados pessoais pelos Serviços de Ação Social da Universidade de Coimbra (SASUC), no
            presente contexto, destina-se a recolher informação para tratamento do requerimento para atribuição do apoio
            extraordinário ao alojamento, no âmbito do Despacho n.º 3163/2023, de 9 de março.
          </p>
          <p>
            2) O responsável pelo tratamento são os SASUC, através da Divisão de Acolhimento e Integração, que garante o
            cumprimento do disposto no Regulamento Geral de Proteção de Dados (RGPD), bem como na demais legislação
            aplicável, obrigando-se a respeitar e a cumprir os direitos expressos no ponto 6., quando aplicáveis, nos
            termos legais, e a não colocar à disposição de terceiros não envolvidos no tratamento deste processo os
            dados pessoais, de forma nominativa, sem a minha autorização pessoal.
          </p>
          <p>
            3) Os dados pessoais solicitados pelos SASUC são os que constam no presente formulário, sendo os mesmos
            conservados pelo período de 3 anos, atenta a possibilidade de fiscalização, pela Direção-Geral do Ensino
            Superior, prevista no n.º 11 do Despacho supramencionado.
          </p>
          <p>
            4) Não existem comunicações, interconexões ou fluxos transfronteiriços dos dados pessoais identificáveis no
            destinatário.
          </p>
          <p>
            5) Os SASUC garantem a anonimização dos dados pessoais recolhidos através deste formulário, caso venha a ser
            demonstrada a necessidade de proceder à divulgação pública dos resultados deste processo.
          </p>
          <p>
            6) Na qualidade de titular dos dados, em conformidade com a Lei, tenho o direito de acesso, o direito de
            retificação, o direito de apagamento, o direito de limitação do tratamento, o direito de portabilidade, o
            direito de oposição e o direito de não ficar sujeito a decisões automatizadas, podendo estes ser exercidos a
            qualquer momento, por escrito, através de gabadmin@sas.uc.pt.
          </p>
          <p>O Encarregado de Proteção de Dados da UC pode ser contactado através de epd@uc.pt.</p>
          <p>
            Para os devidos efeitos, declaro ter tomado pleno conhecimento e compreendido o teor da informação legal ora
            prestada.
          </p>
        </div>
      </div>
    </fw-panel>
  </div>
</template>

<script>
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import CountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/CountriesSelect'
import PhoneCountriesSelect from '@/fw-modules/fw-core-vue/ui/components/form/PhoneCountriesSelect'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import OnoffOption from '@/fw-modules/fw-core-vue/ui/components/form/onoffOption'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { required, maxLength, minLength, minValue, integer } from 'vuelidate/lib/validators'
import { postalCode, vat, validCC } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'

export default {
  components: {
    TextInput,
    NumberInput,
    CountriesSelect,
    PhoneCountriesSelect,
    Uploader,
    OnoffOption,
    RecordFileEntry,
  },

  props: {
    checks: {
      type: Object,
      default: () => {
        return {}
      },
    },

    application: {
      type: Object,
      default: () => {
        return {}
      },
    },

    call: {
      type: Object,
      default: () => {
        return {}
      },
    },

    slots: {
      type: Array,
      default: () => [],
    },

    steps: {
      type: Array,
      default: () => {
        return []
      },
    },

    savingData: {
      type: Boolean,
    },
  },

  validations() {
    return {
      application: {
        data: {
          // Personal data
          name: { required, min: minLength(3), max: maxLength(255) },
          address: { required, min: minLength(3) },
          postal_code: { required, validPostalCode: postalCode(true) },
          locality: { required, min: minLength(3), max: maxLength(255) },
          country_code: { required },
          phone_number: { required, min: minLength(3), max: maxLength(255) },
          identity_type: { required },
          identity_value: { required, min: minLength(3), max: maxLength(50), validCC },
          identity_expire_date: { required, min: minValue(this.minDate) },
          vat_value: { required, vat },
          vat_country_code: { required },

          iban: { required, integer, min: minLength(21), max: maxLength(21) },
          account_holder: {
            required: value => [true, 1].includes(this.application.data.is_account_holder) || Boolean(value),
          },
          address_during_classes: {
            address: {
              required,
              min: minLength(3),
            },
            postal_code: {
              required,
              validPostalCode: postalCode(true),
            },
            locality: {
              required,
              min: minLength(3),
              max: maxLength(255),
            },
            country_code: {
              required,
            },
          },
          academic_info: {
            student_number: { required, min: minLength(5) },
            course: {
              required,
              min: minLength(1),
              max: maxLength(255),
            },
            institution: {
              required,
              min: minLength(1),
              max: maxLength(255),
            },
            study_cycle: {
              required,
              min: minLength(1),
              max: maxLength(255),
            },
          },

          // Acceptance
          accept_extraordinary_support_for_housing: { checked: value => value === true },
          accept_data_treatment: { checked: value => value === true },
          entered_required_docs: { checked: value => value === true },
        },

        files: {
          household_fiscal_address: { required, min: minLength(1) },
          family_allowance: { required, min: minLength(1) },
          permanent_residence: { required, min: minLength(1) },
          non_existence_of_debts: { required, min: minLength(1) },
          payment_receipts: { required, min: minLength(1) },
          iban: { required, min: minLength(1) },
        },
      },
    }
  },

  data() {
    return {
      editMode: true,
      familyAllowanceFiles: [],
      householdFiscalAddressFiles: [],
      ibanFiles: [],
      permanentResidenceFiles: [],
      nonExistenceOfDebtsFiles: [],
      paymentReceiptsFiles: [],
      loading: false,
      minDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    tmpSteps() {
      return [
        {
          text: this.$t('personalData'),
          value: 'personal_data',
          status:
            !this.$v.application.data.name.$invalid &&
            !this.$v.application.data.address.$invalid &&
            !this.$v.application.data.postal_code.$invalid &&
            !this.$v.application.data.locality.$invalid &&
            !this.$v.application.data.country_code.$invalid &&
            !this.$v.application.data.phone_number.$invalid &&
            !this.$v.application.data.identity_type.$invalid &&
            !this.$v.application.data.identity_value.$invalid &&
            !this.$v.application.data.identity_expire_date.$invalid &&
            !this.$v.application.data.vat_value.$invalid &&
            !this.$v.application.data.vat_country_code.$invalid &&
            !this.$v.application.data.iban.$invalid &&
            !this.$v.application.data.account_holder.$invalid &&
            !this.$v.application.data.address_during_classes.$invalid
              ? 'finished'
              : 'next',
        },
        {
          text: this.$t('academicInfo.title'),
          value: 'academic_info',
          status: !this.$v.application.data.academic_info.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('files.title'),
          value: 'files',
          status: !this.$v.application.files.$invalid ? 'finished' : 'next',
        },
        {
          text: this.$t('declarationOfHonor'),
          value: 'declaration',
          status:
            !this.$v.application.data.accept_extraordinary_support_for_housing.$invalid &&
            !this.$v.application.data.accept_data_treatment.$invalid &&
            !this.$v.application.data.entered_required_docs.$invalid
              ? 'finished'
              : 'next',
        },
      ]
    },
  },

  watch: {
    tmpSteps(newVal) {
      this.$emit('update:steps', newVal)
    },
    '$v.application.data.$error'(hasError) {
      this.$emit('form-with-errors', hasError)
    },
  },

  created() {
    this.$emit('update:steps', this.tmpSteps)
  },

  methods: {
    getSteps() {
      return this.steps
    },

    countryChanged(country, objectkey = null) {
      console.log('countryChanged', country, objectkey)
      if (objectkey) {
        this.application.data[objectkey].country_code = country.key
      } else {
        this.application.data.country_code = country.key
      }
    },

    phoneCountryChanged(country) {
      console.log('phoneCountryChanged', country)
      this.application.data.phone_country_code = country.key
    },

    identityTypeChanged(value) {
      console.log('identityTypeChanged', value)
      this.application.data.identity_type = value
    },

    applicantIsAccountHolderChanged(value) {
      this.application.data.is_account_holder = value
      console.log('is_account_holder', value, this.application.data.is_account_holder)
    },

    saveApplication() {
      this.$v.application.$touch()
      this.$emit('save', 'update', this.application)
    },

    async uploaded(files, fileType) {
      console.log('uploaded files', files, fileType)

      const uploadedFiles = []
      for (const file of files) {
        if (file.response.data) {
          if (file.response.status === 'success') {
            console.log('file :>> ', file.response.data.file)
            uploadedFiles.push({
              key: file.response.data.file.key,
              filename: file.response.data.file.filename,
              section: fileType,
            })
          }
        }
      }

      if (!this.application.files[fileType]) {
        this.$set(this.application.files, fileType, uploadedFiles)
      } else {
        this.$set(this.application.files, fileType, this.application.files[fileType].concat(uploadedFiles))
      }
      console.log('this.application.files :>> ', this.application.files)
    },

    saveFile(file, fileType) {
      console.log('save filename', file, fileType)
      let currentFile = this.application.files[fileType].find(el => el.key == file.key)
      if (currentFile) currentFile.filename = file.title //this.$set(this.application.files, fileType, file)

      console.log('this.application.files[fileType] :>> ', this.application.files[fileType])
      console.log('current :>> ', currentFile)
    },

    async removeFile(file, fileType) {
      const fileKey = file.key
      try {
        const response = await this.api.deleteApplicationFile(this.call.key, this.application.key, [fileKey])
        console.log('removeFile --> ', file.key, response)
        this.application.files[fileType] = this.application.files[fileType].filter(el => el.key != file.key)
      } catch (error) {
        console.error(error)
      }
    },

    downloadFile(file) {
      this.$emit('download-file', file)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "schoolresidency": "Residência em aulas",
    "yes": "Sim",
    "no": "Não",
    "charsLimit": "Limite de {limit} caracteres.",
    "application": "Candidatura",
    "cancel": "Cancelar",
    "submit": "Submeter",
    "save": "Guardar",
    "uploadFiles": "Carregar ficheiros",
    "acceptance": "Aceitação",
    "fullName": "Nome completo",
    "address": "Morada",
    "postalCode": "Código Postal",
    "locality": "Localidade",
    "country": "País",
    "phoneCountry": "Indicativo do País",
    "phone": "Telefone",
    "id": {
      "label": "Documentos de identificação",
      "type": {
        "label": "Tipo",
        "placeholder": "Selecione um tipo",
        "cc": "Cartão de Cidadão",
        "passport": "Passaporte",
        "residence_permit": "Título de residência"
      },
      "number": "Número",
      "emittedDate": {
        "label": "Data de emissão",
        "placeholder": "Data"
      },
      "validDate": {
        "label": "Data de validade",
        "placeholder": "Data"
      }
    },
    "vat": "Número de contribuinte (português)",
    "personalData": "Dados pessoais",
    "enrolledCourse": {
      "title": "Curso inscrito",
      "label": "Identifique o curso em que está inscrito como estudante",
      "name": "Designação do curso",
      "academicYear": "Ano letivo",
      "institution": "Instituição onde se encontra inscrito",
      "options": {
        "technical_course": "Curso técnico superior profissional",
        "other": "Curso não conferente de grau académico integrado no projeto educativo de uma instituição de ensino superior",
        "graduate": "Licenciatura",
        "integrated_master": "Mestrado integrado",
        "master": "Mestrado",
        "phd": "Doutoramento",
        "not_applicable": "Não aplicável (titular de grau de doutor)"
      }
    },
    "differentAddress": "Tem uma morada diferente em período de aulas",
    "hasErrors": "Existem erros no formulário",
    "accountHolder": "Titular da conta",
    "isAccountHolder": "O/a requerente é um dos titulares da conta?",
    "academicInfo": {
      "title": "Informação académica",
      "studentNumber": {
        "label": "Número de estudante",
        "placeholder": "Indique o seu número de estudante da Universidade de Coimbra"
      },
      "course": {
        "label": "Curso",
        "placeholder": "Indique o nome do curso que frequenta"
      },
      "studyCycle": {
        "label": "Ciclo de Estudos",
        "placeholder": "Indique o ciclo de estudos que frequenta"
      },
      "institution": {
        "label": "Faculdade",
        "placeholder": "Indique a unidade de ensino que frequenta"
      }
    },
    "files": {
      "title": "Documentação obrigatória",
      "householdFiscalAddress": {
        "title": "Comprovativo da morada fiscal do agregado familiar e da morada em tempo de aulas"
      },
      "familyAllowance": {
        "title": "Comprovativo de ser beneficiário, à data do requerimento, de abono de família até ao 3.º escalão"
      },
      "permanentResidence": {
        "title": "Comprovativo de nacionalidade portuguesa ou título de autorização de residência permanente em Portugal ou outro comprovativo do requisito de elegibilidade previsto no artigo 5.º, alínea a) do RABEEES"
      },
      "nonExistenceOfDebts": {
        "title": "Certidões comprovativas da não existência de dívida tributária e contributiva"
      },
      "paymentReceipts": {
        "title": "Recibos de pagamento do alojamento existentes desde o início do ano letivo até à data de submissão do requerimento (recibos eletrónicos ou recibos + contrato de arrendamento)"
      },
      "iban": {
        "title": "Comprovativo do IBAN (número de identificação bancária)"
      }
    },
    "declarationOfHonor": "Declaração de honra",
    "accept_extraordinary_support_for_housing" : "Eu, acima identificado, venho requerer a atribuição do apoio extraordinário ao alojamento previsto no Despacho Nº3163/2023 de 9 de março",
    "entered_required_docs": "Para o efeito, anexo a este requerimento a documentação obrigatória necessária ao tratamento do pedido.",
    "accept_data_treatment": "Mais declaro que tomei conhecimento e que autorizo os termos do tratamento dos meus dados pessoais no âmbito deste pedido.",
    "notDefined": "Não definido",
    "errors": {
      "required": {
        "name": "Indique o seu nome",
        "address": "Indique a morada",
        "postalCode": "Indique o código postal",
        "locality": "Indique a localidade",
        "country": "Indique o seu país",
        "phoneNumber": "Indique o seu número de telefone",
        "identityType": "Indique um documento de identificação",
        "identityCode": "Indique o número do documento de identificação",
        "identityExpireDate": "Indique a data de validade",
        "vat": "Indique um número de contribuinte",
        "files": "Indique um ou mais ficheiros",
        "accept_extraordinary_support_for_housing": "Obrigatório",
        "entered_required_docs": "Obrigatório",
        "accept_data_treatment": "Obrigatório",
        "accountHolder": "Indique o nome do(a) titular da conta",
        "studentNumber": "Indique o número de estudante",
        "course": "Indique o curso",
        "studyCycle": "Indique o ciclo de estudos",
        "institution": "Indique a sua unidade de ensino",
        "iban": "Indique um IBAN português"
      },
      "invalid": {
        "name": "Nome inválido",
        "address": "Morada inválida",
        "postalCode": "Código postal inválido",
        "locality": "Localidade inválida",
        "country": "País inválido",
        "phoneNumber": "Número de telefone inválido",
        "identityCode": "Número inválido",
        "identityExpireDate": "Data inválida",
        "vat": "Número de contribuinte inválido",
        "files": "Ficheiro inválido",
        "accountHolder": "Titular da conta inválido",
        "studentNumber": "Número de estudante inválido",
        "course": "Curso inválido",
        "studyCycle": "Ciclo de estudos inválido",
        "institution": "Instituição inválida",
        "iban": "IBAN inválido"
      }
    }
  },
  "en": {
    "accept_extraordinary_support_for_housing" : "I, above identified, hereby request the award of the extraordinary support for housing provided for in the Decree",
    "entered_required_docs": "For this purpose, I attach to this request the mandatory documentation necessary for the processing of the application.",
    "accept_data_treatment": "I also declare that I have taken note and that I authorize the terms of the treatment of my personal data in the context of this request.",
    "differentAddress": "Has a different address during the school year",
    "files": {
      "title": "Required documentation",
      "householdFiscalAddress": {
        "title": "Proof of the fiscal address of the household and the address during the school year"
      },
      "familyAllowance": {
        "title": "Proof of being a beneficiary, as of the date of the application, of family allowance up to the 3rd scale"
      },
      "permanentResidence": {
        "title": "Proof of being a Portuguese national or of having a permanent residence permit in Portugal or other proof of the eligibility requirement provided for in Article 5, paragraph a) of the RABEEES"
      },
      "nonExistenceOfDebts": {
        "title": "Certificates proving the non-existence of tax and contributory debt"
      },
      "paymentReceipts": {
        "title": "Payment receipts of the accommodation existing since the beginning of the school year until the date of submission of the application (electronic receipts or receipts + rental contract)"
      },
      "iban": {
        "title": "Proof of IBAN (bank account number)"
      }
    },
    "accountHolder": "Account holder",
    "isAccountHolder": "Is account holder",
    "academicInfo": {
      "title": "Academic Information",
      "studentNumber": {
        "label": "Student Number",
        "placeholder": "Enter the Student Number"
      },
      "course": {
        "label": "Course",
        "placeholder": "Enter the Course"
      },
      "studyCycle": {
        "label": "Study Cycle",
        "placeholder": "Enter the Study Cycle"
      },
      "institution": {
        "label": "Institution",
        "placeholder": "Enter the Institution"
      }
    },
    "schoolresidency": "School Residency",
    "yes": "Yes",
    "no": "No",
    "charsLimit": "Limit of {limit} characters.",
    "application": "Application",
    "cancel": "Cancel",
    "submit": "Submit",
    "save": "Save",
    "uploadFiles": "Upload files",
    "acceptance": "Acceptance",
    "fullName": "Full Name",
    "address": "Address",
    "postalCode": "Postal Code",
    "locality": "City",
    "country": "Country",
    "phoneCountry": "Country calling code",
    "phone": "Phone",
    "email": "E-mail",
    "notDefined": "Not defined",
    "id": {
      "label": "Identification documents",
      "type": {
        "label": "Type",
        "placeholder": "Select a type",
        "cc": "Citizen ID card",
        "passport": "Passport",
        "residencePermit": "Residence permit"
      },
      "number": "Number",
      "emittedDate": {
        "label": "Emission date",
        "placeholder": "Date"
      },
      "validDate": {
        "label": "Validity date",
        "placeholder": "Date"
      }
    },
    "declarationOfHonor": "Declaration of Honor",
    "vat": "VAT Number",
    "personalData": "Personal details",
    "hasErrors": "There are errors in the form",
    "errors": {
      "required": {
        "name": "Name is required",
        "email": "Email is required",
        "address": "Address is required",
        "postalCode": "Postal code is required",
        "locality": "Enter your locality",
        "country": "Select your country",
        "phoneNumber": "Enter your phone number",
        "identityType": "Insert an identification document",
        "identityCode": "Enter your ID document number",
        "identityExpireDate": "Enter the expiry date",
        "vat": "Enter the VAT number",
        "files": "Add one or more files",
        "accept_extraordinary_support_for_housing": "Accept the extraordinary support for housing",
        "entered_required_docs": "Attach the required documentation",
        "accept_data_treatment": "Accept the treatment of your personal data",
        "accountHolder": "Indicate if you are the account holder",
        "studentNumber": "Indicate the student number",
        "course": "Indicate the course",
        "studyCycle": "Indicate the study cycle",
        "institution": "Indicate the institution"
      },
      "invalid": {
        "name": "Invalid name",
        "email": "Invalid email",
        "address": "Invalid address",
        "postalCode": "Invalid postal code",
        "locality": "Invalid locality",
        "country": "Invalid country",
        "phoneNumber": "Invalid phone number",
        "identityCode": "Invalid ID document number",
        "identityExpireDate": "Invalid date",
        "vat": "VAT",
        "scienceId": "Science ID",
        "habilitationLevel": "Invalid qualification level",
        "scientificArea": "Invalid scientific area",
        "academicYear": "Invalid academic year",
        "files": "Invalid file",
        "accountHolder": "Invalid account holder",
        "studentNumber": "Invalid student number",
        "course": "Invalid course",
        "studyCycle": "Invalid study cycle",
        "institution": "Invalid institution"
      }
    }
  }
}
</i18n>

<style lang="postcss">
.update-modal-uploader .file-uploads {
  @apply w-full mt-1;
}
</style>
