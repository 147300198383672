<template>
  <fw-layout back-to="/" :loading="initialLoading" loading-title="Apoio S+">
    <template v-if="currentView !== 'dashboard'" #header-nav>
      <div class="flex gap-5 items-center">
        <div class="flex flex-col">
          <div v-if="call.title[language].length" class="flex gap-2 max-w-md">
            <v-clamp autoresize :max-lines="1" class="font-semibold text-md leading-5 max-w-prose">
              {{ call.title[language] }}
            </v-clamp>
            <div class="opacity-70 text-xs">{{ call.prefix }}{{ call.code }}</div>
          </div>
          <div v-else class="text-gray-500">{{ $t('notitle') }}</div>
          <fw-label size="sm" custom-class="hidden sm:flex items-center gap-1" marginless>
            <span>Candidatura</span>
            <span class="is-family-code font-medium">{{ application.key }}</span>
          </fw-label>
        </div>
        <div v-if="application.state">
          <fw-tag size="sm" :type="applicationStates[application.state].color" custom-class="px-3">
            {{ applicationStates[application.state].label[language] }}
          </fw-tag>
        </div>
      </div>
      <div v-if="showTimeCounter" class="mx-2">
        <TimeCounter :remaining-seconds="remainingSeconds"></TimeCounter>
      </div>
    </template>

    <template #header-toolbar>
      <div class="flex gap-2 items-center mx-5">
        <fw-button
          v-if="application.state == 'draft'"
          :type="checks.canSubmit ? 'primary' : 'light'"
          :disabled="savingData || !checks.canSubmit || formWithErrors"
          @click.native="submitApplication"
        >
          Submeter
        </fw-button>
        <fw-button
          v-if="checks.canCancel"
          ref="cancel-button"
          type="transparent"
          :disabled="savingData"
          @click.native="cancelApplication"
        >
          Cancelar
        </fw-button>
        <fw-button
          v-if="checks.canReopen"
          ref="reopen-button"
          type="light-primary"
          :disabled="savingData"
          @click.native="reopenApplication"
        >
          Reabrir candidatura
        </fw-button>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarApplication
        :view="currentView"
        :steps="steps"
        :checks="checks"
        :section="section"
        @go-to-view="goToView"
        @go-to-section="goToSection"
      />
    </template>

    <template #main-content>
      <PanelApplicationDashboard
        v-if="!currentView || currentView === 'dashboard'"
        :saving-data.sync="savingData"
        :application.sync="application"
        :call="call"
        :checks="checks"
        :steps.sync="steps"
        class="pb-5"
        @go-to-view="goToView"
        @form-with-errors="formWithErrors = $event"
        @save="saveData"
        @added-message="getApplication"
        @download-file="downloadFile"
      />
      <PanelApplicationMetadata
        v-else-if="currentView === 'metadata'"
        ref="applicationScholarships"
        :saving-data.sync="savingData"
        :application.sync="application"
        :call="call"
        :checks="checks"
        :steps.sync="steps"
        class="pb-5"
        @form-with-errors="formWithErrors = $event"
        @save="saveData"
        @download-file="downloadFile"
      />
      <PanelApplicationReceipts
        v-else-if="currentView === 'receipts'"
        :application="application"
        :call="call"
        :checks="checks"
      />
    </template>

    <template #modals>
      <fw-modal v-if="showReceipt" :active.sync="showReceipt" :can-cancel="false" size="xs">
        <template #default>
          <CheckmarkSuccess />
          <div class="text-center flex flex-col gap-3 px-5">
            <fw-heading size="h3">Candidatura submetida!</fw-heading>
            <div class="text-xs text-gray-500">
              O seu processo irá ser analisado tão breve quanto possível.
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button type="black" expanded @click.native="closeShowReceipt">Fechar</fw-button>
        </template>
      </fw-modal>
      <fw-modal
        v-if="timerInterval"
        :active.sync="showTimeAlmostOverWarning"
        title="O prazo está prestes a terminar!"
        title-align="center"
        :can-cancel="false"
        @close="ackTimeIsAlmostOver()"
      >
        <template #default>
          <div class="flex flex-col items-center text-center max-w-xs mx-auto">
            <div class="flex justify-center pb-3">
              <TimeCounter size="lg" :remaining-seconds="remainingSeconds"></TimeCounter>
            </div>
            <div class="flex flex-col gap-2 text-sm">
              <div class="font-semibold">
                Alertamos que o prazo limite para submissão de candidatura ao abrigo do procedimento
                {{ call.prefix }}{{ call.code }} está prestes a terminar.
              </div>
              <div>Caso não submeta a sua candidatura, antes de terminar o prazo, a mesma será <b>cancelada</b>.</div>
            </div>
          </div>
        </template>
        <template #footer>
          <fw-button type="black" expanded @click.native="ackTimeIsAlmostOver()">
            Continuar
          </fw-button>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import SidebarApplication from '@/components/sidebars/SidebarApplication'
import PanelApplicationMetadata from '@/components/panels/PanelApplicationMetadata'
import PanelApplicationDashboard from '@/components/panels/PanelApplicationDashboard'
import PanelApplicationReceipts from '@/components/panels/PanelApplicationReceipts'
import CheckmarkSuccess from '@/fw-modules/fw-core-vue/ui/components/animation/CheckmarkSuccess'
import TimeCounter from '@/fw-modules/fw-core-vue/forms/components/text/TimeCounter'

import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import { APPLICATION_STATES } from '@/utils/index.js'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    SidebarApplication,
    PanelApplicationDashboard,
    PanelApplicationMetadata,
    PanelApplicationReceipts,
    CheckmarkSuccess,
    TimeCounter,
  },

  data() {
    return {
      loading: false,
      initialLoading: true,
      savingData: false,
      showReceipt: false,
      formWithErrors: false,
      application: {},
      validations: {},
      users: {},
      call: {},
      steps: [],
      alertReadyToSubmitShown: false,
      applicationStates: APPLICATION_STATES['scholarship'],
      remainingSeconds: 0,
      remainingSecondsToShowCounter: 3600, // 1 hour
      secondsToShowTimeAlmostOverWarning: 60 * 10,
      showTimeAlmostOverWarning: false,
      timeAlmostOverWarningShown: false,
      timerInterval: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    currentView() {
      return this.$route.params.view || 'metadata'
    },

    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },

    callKey() {
      return this.$route.params.key
    },

    applicationKey() {
      return this.$route.params.applicationKey
    },

    language() {
      return this.$store.state.language
    },

    section() {
      return this.$route.params.action
    },

    showTimeCounter() {
      // Be careful!!! Submit will depend on this
      return (
        this.application.state === 'draft' &&
        this.timerInterval != null &&
        this.remainingSeconds < this.remainingSecondsToShowCounter
      )
    },

    // Checks
    checks() {
      return {
        canEdit: this.canEdit,
        canSubmit: this.canSubmit,
        canCancel: this.canCancel,
        canReopen: this.canReopen,
        isSubmitted: this.isSubmitted,
        isCanceled: this.isCanceled,
        isDraft: this.isDraft,
        isClosed: this.isClosed,
        isAccepted: this.isAccepted,
        isRejected: this.isRejected,
        canSendMessages: this.canSendMessages,
      }
    },

    canEdit() {
      return this.validations && this.validations.can_edit
    },
    canSubmit() {
      return (
        this.application &&
        this.validations &&
        this.validations.can_submit &&
        this.application.state == 'draft' &&
        this.remainingSeconds > 0 &&
        !this.formWithErrors
      )
    },
    canCancel() {
      return this.validations && this.validations.can_delete
    },
    canReopen() {
      return this.validations && this.validations.can_reopen
    },
    isSubmitted() {
      return this.application && this.application.state.includes(['submitted', 'waiting_docs', 'complaint'])
    },
    isCanceled() {
      return this.application && this.application.state == 'canceled'
    },
    isDraft() {
      return this.application && this.application.state == 'draft'
    },
    isClosed() {
      return this.application && this.application.state == 'closed'
    },
    isRejected() {
      return this.application && this.application.decision === 'rejected'
    },
    isAccepted() {
      return this.application && this.application.decision === 'accepted'
    },
    canSendMessages() {
      return this.application && this.validations && this.validations.can_send_message
    },
  },

  mounted() {
    this.getApplication()
  },

  beforeDestroy() {
    this.stopTime()
  },

  methods: {
    goToView(view) {
      this.view = view
      this.$router.push({
        name: 'application-view',
        params: { key: this.call.key, applicationKey: this.application.key, view: view },
      })
    },

    goToSection(name) {
      this.scrollToSection(name)
    },

    async downloadFile(file) {
      console.log('downloadFile', file)
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    async removeFile(file, fileType) {
      const fileKey = file.key
      try {
        const response = await this.api.deleteFile(fileKey)
        console.log('removeFile', file.key, response)
        this.application.files[fileType] = this.application.files[fileType].filter(el => el.key != fileKey)
      } catch (error) {
        console.error(error)
      }
    },

    closeShowReceipt() {
      this.showReceipt = false
      this.goToView('dashboard')
    },

    submitApplication() {
      if (!this.canSubmit) return
      this.$buefy.dialog.confirm({
        title: 'Tem a certeza que deseja submeter a sua candidatura?',
        message: 'Após submissão, a mesma não poderá ser alterada.',
        confirmText: 'Submeter',
        cancelText: 'Cancelar',
        onConfirm: () => {
          this.saveData('save')
          this.saveData('submit')
        },
      })
    },

    reopenApplication() {
      if (!this.canReopen) return
      this.saveData('draft')
    },

    cancelApplication() {
      this.$buefy.dialog.confirm({
        type: 'is-black',
        title: 'Tem a certeza que deseja cancelar a sua candidatura?',
        message: 'Se cancelar, poderá voltar a reabrir desde que o prazo de candidaturas ainda esteja a decorrer.',
        confirmText: 'Cancelar candidatura',
        cancelText: 'Fechar',
        onConfirm: () => {
          this.saveData('cancel')
        },
      })
    },

    async saveData(type = null, application = null) {
      this.savingData = true

      if (!type) type = 'update'
      if (!application) application = this.application

      console.log('Save application: ', application)

      let tmpApplication = JSON.parse(JSON.stringify({ data: application.data, files: application.files }))

      if (type === 'submit') {
        tmpApplication['state'] = 'submitted'
      } else if (type === 'cancel') {
        tmpApplication['state'] = 'canceled'
      } else if (type === 'draft') {
        tmpApplication['state'] = 'draft'
      }

      // Parse date
      tmpApplication.data.identity_expire_date = tmpApplication.data.identity_expire_date
        ? Dates.formatDateToAPI(tmpApplication.data.identity_expire_date)
        : null

      // Parse IBAN (just in case)
      if (application.data.iban && !tmpApplication.data.iban.startsWith('PT50')) {
        tmpApplication.data.iban = 'PT50' + tmpApplication.data.iban.replace(/\s/g, '')
      }

      let files = []
      Object.values(tmpApplication.files).forEach(element => {
        files = [...files, ...element]
      })
      tmpApplication.files = files

      console.log('Save application: ', tmpApplication)

      utils.tryAndCatch(this, async () => {
        const response = await this.api.updateApplication(this.callKey, this.applicationKey, tmpApplication)
        console.log('Got response: ', response)
        let application = response.application
        if (response.validations) this.validations = response.validations

        this.setApplicationData(application)

        // Show receipt if submitted
        if (type === 'submit' && response.application.state == 'submitted') {
          setTimeout(() => {
            this.showReceipt = true
          }, 100)
          this.getApplication()
        }

        // User feedback
        else if (type === 'update') {
          // Add more info when we save data
          var msg = 'Os dados foram guardados.'
          if (this.formWithErrors) {
            msg = 'Os dados foram guardados mas existem erros no formulário.'
          }

          this.$buefy.snackbar.open({
            message: msg,
            type: 'is-white',
            position: 'is-bottom-right',
            indefinite: false,
            duration: 2000,
            queue: false,
          })
          if (!this.formWithErrors && this.canSubmit && !this.alertReadyToSubmitShown) {
            setTimeout(() => {
              this.$buefy.snackbar.open({
                message: 'A sua candidatura está pronta a ser submetida! 🎉',
                type: 'is-primary',
                position: 'is-bottom-right',
                indefinite: false,
                duration: 4000,
                queue: false,
              })
            }, 1750)
            this.alertReadyToSubmitShown = true
          }
        }
      })

      this.savingData = false
    },

    setApplicationData(application) {
      console.log('application', application)
      if (application.data) {
        // Country (main address)
        application.data.country_code = application.data.country_code || 'PT'

        application.data.phone_country_code = application.data.phone_country_code || 'PT'
        if (application.data.phone_number) {
          application.data.phone_number = Number(application.data.phone_number)
        }

        // Vat number (convert to number)
        application.data.vat_value = application.data.vat_value ? Number(application.data.vat_value) : null

        // Account holder (convert to boolean)
        application.data.is_account_holder = Boolean(application.data.is_account_holder)

        // Address during_classes
        if (application.data.address_during_classes) {
          application.data.address_during_classes.country_code =
            application.data.address_during_classes.country_code || 'PT'
          application.data.address_during_classes.phone_country_code =
            application.data.address_during_classes.phone_country_code || 'PT'
          if (application.data.address_during_classes.phone_number) {
            application.data.address_during_classes.phone_number = Number(
              application.data.address_during_classes.phone_number
            )
          }
        }

        // Parse IBAN (just in case)
        if (application.data.iban && application.data.iban.startsWith('PT50')) {
          application.data.iban = application.data.iban.replace('PT50', '')
        }

        application.data.identity_expire_date = application.data.identity_expire_date
          ? Dates.build(application.data.identity_expire_date).toDate()
          : null

        // Force data

        if (!application.data.address_during_classes) {
          application.data.address_during_classes = {
            address: null,
            postal_code: null,
            locality: null,
            country_code: 'PT',
            phone_number: null,
            phone_country_code: 'PT',
          }
        }

        if (!application.data.academic_info) {
          application.data.academic_info = {
            student_number: null,
            course: null,
            institution: null,
            study_cycle: null,
          }
        }

        // Number
        if (this.user.number) {
          application.data.academic_info.student_number = this.user.number
        }

        // VAT country (PT)
        application.data.vat_country_code = 'PT'
      }

      // Application data (empty)
      else {
        application.data = {
          name: this.user.full_name,
          address: null,
          postal_code: null,
          locality: null,
          country_code: 'PT',
          phone_number: null,
          phone_country_code: 'PT',
          identity_type: null,
          identity_value: null,
          identity_expire_Date: null,
          vat_value: null,
          vat_country_code: 'PT',
          iban: null,
          is_account_holder: null,
          account_holder: null,
          academic_info: {
            student_number: this.user.number,
            course: null,
            institution: null,
            study_cycle: null,
          },
          address_during_classes: {
            address: null,
            postal_code: null,
            locality: null,
            country_code: null,
          },
        }
      }

      const files = {
        household_fiscal_address: [],
        family_allowance: [],
        permanent_residence: [],
        non_existence_of_debts: [],
        payment_receipts: [],
        iban: [],
      }

      if (application.files && application.files.length) {
        application.files.forEach(file => {
          if (file.section) files[file.section].push(file)
        })
      }

      application.files = files

      console.log('application.files :>> ', files)

      this.application = application
    },

    async getApplication() {
      this.loading = true

      try {
        const response = await this.api.getApplication(this.callKey, this.applicationKey)
        console.log('getApplication response :>>', response)
        let application = response.application
        this.call = response.call
        this.validations = response.validations
        this.users = response.users
        this.setApplicationData(application)

        // Update remaining time to submit application
        this.remainingSeconds = this.call.time_to_end

        // Start clock
        if (this.remainingSeconds > 0) this.startTime()
      } catch (error) {
        console.log('Error getApplication :>> ', error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 250)
        setTimeout(() => {
          // We just need to turn off once (after first loading)
          if (this.initialLoading) this.initialLoading = false
        }, 500)
      }
    },

    saveChildApplication() {
      this.$refs.applicationScholarships.saveApplication()
    },

    scrollToSection(id) {
      let section = document.querySelector('#form_section_' + id)
      if (section) {
        let top = section.getBoundingClientRect().top
        document.querySelector('.main-content').scroll({
          top: document.querySelector('.main-content').scrollTop + top - 70,
          behavior: 'smooth', // 👈
        })
      }
    },

    // Deal with time
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },
    startTime() {
      let self = this
      this.timeTick()
      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)
    },
    timeTick() {
      this.remainingSeconds -= 1
      this.checkTimeWarnings()
      //console.log('time tick', this.remainingSeconds)
    },
    checkTimeWarnings() {
      // Show critical warning - time is almost over, dude!
      // Make sure we show this only when time is not over
      if (
        this.remainingSeconds > 0 &&
        this.remainingSeconds < this.secondsToShowTimeAlmostOverWarning &&
        this.application.state === 'draft' &&
        !this.timeAlmostOverWarningShown
      ) {
        this.showTimeAlmostOverWarning = true
        this.timeAlmostOverWarningShown = true
      }

      // Check time is over
      if (this.remainingSeconds <= 0) {
        this.stopTime()
        console.log('Time is over!')

        // Redirect to call page
        setTimeout(() => {
          this.$router.go()
        }, 150)
      }
    },
    ackTimeIsAlmostOver() {
      this.showTimeAlmostOverWarning = false
      // Log this activity
      this.logActivity('timeover-warning-ack')
      console.log('User ack warning of time almost over.')
    },
    logActivity(action, metadata = {}, byUserInteraction = true) {
      this.$store.dispatch('saveActivityAction', {
        service: 'socialsupport',
        metadata: metadata,
        action: action,
        elementType: 'application',
        elementID: this.application != null ? this.application.key : 'NULL',
        userInteraction: byUserInteraction,
      })
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "application": {
        "state": {
          "submitted": "Submetida",
          "draft": "Rascunho",
          "rejected": "Rejeitada",
          "canceled": "Cancelada",
          "in_analysis": "Submetida",
          "waiting_docs": "Aguarda documentos",
          "complaint": "Em fase de reclamação",
          "accepted": "Aceite",
          "closed": "Fechada"
        }
      }
    },
    "pt": {
      "application": {
       "state": {
          "submitted": "Submetida",
          "draft": "Rascunho",
          "rejected": "Rejeitada",
          "canceled": "Cancelada",
          "in_analysis": "Submetida",
          "waiting_docs": "Aguarda documentos",
          "complaint": "Em fase de reclamação",
          "accepted": "Aceite",
          "closed": "Fechada"
        }
      }
    }
  }

</i18n>
